import React, { ReactNode, RefObject, useEffect} from 'react'


import { Swiper, SwiperSlide } from 'swiper/react';
import {Zoom, Autoplay, Pagination, Keyboard, Scrollbar} from 'swiper/modules'
 
import './GuruSlider.scss'
// Import Swiper styles
import "swiper/css";
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import 'swiper/css/scrollbar';
import 'swiper/css/keyboard';



interface GuruSliderProps {
    className?: string
    id?: string;
    pager?: boolean
    scrollbar?: boolean
    autoplay?: boolean | any
    keyboard?: boolean
    zoom?: boolean
    slidesPerView?: number | 'auto'
    autoHeight?: boolean
    speed?: number,
    initialSlide?: number,
    onSlideTransitionStart?: () => void
    allowTouchMove?: boolean
    sliderRef?: RefObject<any>
    spaceBetween?: string | number
    observer?: boolean
    observeParents?: boolean
    slidesOffsetBefore?: number
    slidesOffsetAfter?: number
    onActiveIndexChange?: (swiper: any) => void
    children: ReactNode
    direction?: 'horizontal' | 'vertical'
    breakpoints?: { [width: number]: any }
}

export const GuruSlider: React.FC<GuruSliderProps> = ({
    className,
    id,
    pager,
    scrollbar,
    autoplay,
    keyboard,
    zoom,
    slidesPerView = 1,
    autoHeight = false,
    speed = 300,
    initialSlide = 0,
    onSlideTransitionStart,
    allowTouchMove = true,
    sliderRef,
    spaceBetween = 0,
    observer = false,
    observeParents = false,
    slidesOffsetBefore = 0,
    slidesOffsetAfter = 0,
    onActiveIndexChange,
    children,
    direction = 'horizontal',
    breakpoints
}) => {

    // useEffect(() => {
    //     console.log({
    //         className,
    //         id,
    //         pager,
    //         scrollbar,
    //         autoplay,
    //         keyboard,
    //         zoom,
    //         slidesPerView,
    //         autoHeight,
    //         speed,
    //         initialSlide,
    //         onSlideTransitionStart,
    //         allowTouchMove,
    //         sliderRef,
    //         spaceBetween,
    //         observer,
    //         observeParents,
    //         slidesOffsetBefore,
    //         slidesOffsetAfter,
    //         onActiveIndexChange,
    //         children,
    //         direction,
    //         breakpoints
    //     })
    // }, [])

    const getSwiperModules = () => {
        let modules = []
        if (pager) modules.push(Pagination)
        if (scrollbar) modules.push(Scrollbar)
        // if (autoplay) modules.push(Autoplay)
        if (keyboard) modules.push(Keyboard)
        if (zoom) modules.push(Zoom)
        return modules
    }

    return (
        <Swiper 
            id={id}
            ref={sliderRef || undefined}
            className={`guru-slider-container ${className ?? ''}`}
            modules={getSwiperModules()}
            slidesPerView={slidesPerView}
            autoplay={autoplay}
            keyboard={keyboard}
            pagination={pager}
            scrollbar={scrollbar}
            zoom={zoom}
            speed={speed}
            initialSlide={initialSlide}
            onTransitionStart={onSlideTransitionStart}
            autoHeight={autoHeight}
            allowTouchMove={allowTouchMove}
            spaceBetween={spaceBetween}
            observer={observer}
            observeParents={observeParents}
            slidesOffsetBefore={slidesOffsetBefore}
            slidesOffsetAfter={slidesOffsetAfter}
            onActiveIndexChange={onActiveIndexChange}
            direction={direction}
            breakpoints={breakpoints}
        >
            {React.Children.map(children, (child, index) => (
                <SwiperSlide key={index}>
                    {child}
                </SwiperSlide>
            ))}
        </Swiper>
    )
}