import React from 'react'
import { TGroupedProducts, TRouteProps } from '../../pages/home-brand-detail/HomeBrandDetail'
import GlobalComponents from '../'
import { RouteComponentProps } from 'react-router'
import ProductSlider from '../product-slider'

type TBrandVendorsProps = TRouteProps & RouteComponentProps & {
  brandId: string;
  providers: TGroupedProducts[];
  pressSeeAll(providerId: string, categoryId: string, providerName: string): void;
  goToCart(): void;
}

export const BrandVendors: React.FC<TBrandVendorsProps> = (props) => {
  const {providers, brandId, goToCart, pressSeeAll} = props;

  return (
    <>
      {
        providers.map((item: any ) => { 
          return(
            <ProductSlider
              key={item._id}
              products={item.products}
              placementName={`home_brand_detail_product_slider`}
              info={{
                title: item.name,
                id: item._id,
                brandId: brandId,
                hide_more: false,
                vendorName: item.name,
                vendorId: brandId
              }}
              topProduct={false}
              onMoreInfoClick={() => {
                pressSeeAll(item.providerId, item._id, item.name)
              }}
              onGoToCart={() => goToCart()}
              fromSlider={true}
              {...props}
            />
          )
        })
      }
    </>
  )
}
