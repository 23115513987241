import React from 'react'
import Settings from '../../models/Settings'
import { ProductCard } from './../product-card'
import { CartModel } from '../../models/CartModel'
import { Product } from '../../models/ProductModel'
import { VisibilityTracker } from '../../hoc'
import './ProductSlider.scss'


interface IProps {
  settings?: Settings
  products: Product[]
  info: {
    title: string
    id: string
    hide_more?: boolean
    brandId: string
  }
  onMoreInfoClick: Function
  cartModel?: CartModel
  onGoToCart?: () => void
  historyProps:any
  fromSlider:any
}

interface IState {}

export default class ProductSlider extends React.Component<IProps, IState> {
  
  state: IState = {}
  slideId = `slider-${new Date().getTime()}`

  componentDidMount() {
    const slides = document.getElementById(this.slideId) as any

    slides.options = {
      slidesPerView: 2.5,
      slidesOffsetBefore: 24,
      spaceBetween: 12,
      autoplay: {
        delay: 10000 + Math.random() * 2000,
        disableOnInteraction: true,
      },
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
        return;
    };
  }

  // const handleWhenBrandIsVisible = (brand: Brand) => {
  //       registerHomeBrandSliderItemEvent({
  //           eventName: `${placementName}_carousel_brand_view`,
  //           brandId: brand._id,
  //           brandName: brand.name,
  //           placementName: placementName,
  //       })

  //       if(onBrandIsVisible) {
  //           onBrandIsVisible()
  //       }

  //   }
    
  //   const handleWhenBrandIsClicked = (brand: Brand) => {
  //       registerHomeBrandSliderItemEvent({
  //           eventName: `${placementName}_carousel_brand_click`,
  //           brandId: brand._id,
  //           brandName: brand.name,
  //           placementName: placementName,
  //       })

  //       if(onBrandIsClicked) {
  //           onBrandIsClicked(brand)
  //       }
  //   }

  render() {
    const { onGoToCart } = this.props

    return (
      <div className="product-slider">
        <div className="header" style={{ backgroundColor: '#d2d1d1a3' }}>
          <span>{this.props.info.title}</span>
        </div>
        <div className="body">
          <div key={this.slideId} id={this.slideId}>
            {this.props.products.map(product => (
              <div className="contairner-card" key={product.id}>
                  <ProductCard
                    key={product.id}
                    product={product}
                    settings={this.props.settings}
                    cartModel={this.props.cartModel}
                    onGoToCart={onGoToCart}
                    history={this.props.historyProps}
                  ></ProductCard>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
}
