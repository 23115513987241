import React, { useEffect, useState, useCallback, useRef } from 'react';
import { 
  IonModal, 
  IonIcon, 
  createAnimation, 
  IonImg, 
  IonContent, 
  IonItem, 
  IonButtons, 
  IonButton,
  IonToolbar,
  IonList,
  IonTitle
} from '@ionic/react';

import { IoClose as CloseIcon } from "react-icons/io5";

// import { sendMetric } from '../firebase/firebaseTags';
// import closeIcon from '../../assets/icons/nav_close_white_x.svg';
import User from '../../models/User';
import { ProductModel } from '../../models/ProductModel';
import { ProviderModel } from '../../models/ProviderModel';
import { manageBannerClick } from '../../utils/deeplinks';
import { registerInvasiveBannerEvent } from '../firebase/firebaseTags';
import { PromotionModalClass } from '../../interfaces/promotionModal';
import './PromotionModal.refactored.scss';

interface PromotionModalProps {
  user?: User;
  history?: any;
  isOpenPromotion: boolean;
  promotionModal: any;
  closePromotionModal: (promotionModal: any) => void;
  productModel?: ProductModel;
  providerModel?: ProviderModel;
  validateDeeplinks?: () => void;
  placementName?: string
}

const PromotionModal: React.FC<PromotionModalProps> = ({
  history,
  validateDeeplinks,
  promotionModal,
  isOpenPromotion,
  closePromotionModal,
  placementName
}) => {
  const [promotion, setPromotion] = useState<PromotionModalClass | undefined>(undefined);
  const modal = useRef<HTMLIonModalElement>(null);

  useEffect(() => {
    if(promotionModal){
      const promotionInstance = PromotionModalClass.fromJson(promotionModal);
      setPromotion(promotionInstance);
    }
  }, [promotionModal]);

  useEffect(() => {
    if (isOpenPromotion && promotion) {
      handleInvasiveBannerViewEvent();
    }
  }, [isOpenPromotion, promotion]);

  const handleInvasiveBannerViewEvent = () => {
    const placement = placementName ?? "not-defined";

    registerInvasiveBannerEvent({
      eventName: 'invasive_modal_banner_view',
      bannerId: promotion?._id,
      sellerName: promotion?.name,
      placementName: placement,
    });
  };

  const onImageClick = useCallback(async () => {
    const placement = placementName ?? "not-defined";

    registerInvasiveBannerEvent({
      eventName: 'home_invasive_modal_banner_click',
      bannerId: promotion._id,
      sellerName: promotion.name,
      placementName: placement
    });

    await manageBannerClick(promotion, history, validateDeeplinks);
  }, [promotion, history, validateDeeplinks, placementName]);

  const handleCloseModal = useCallback(() => {
    const placement = placementName ?? "not-defined";

    registerInvasiveBannerEvent({
      eventName: 'home_invasive_modal_banner_close',
      bannerId: promotion._id,
      sellerName: promotion.name,
      placementName: placement,
    });
    
    closePromotionModal(promotionModal);
    modal.current?.dismiss();
  }, [closePromotionModal, promotion, promotionModal, placementName]);

  const enterAnimation = (baseEl: HTMLElement) => {
    const root = baseEl.shadowRoot;
    const backdropAnimation = createAnimation()
      .addElement(root?.querySelector('ion-backdrop')!)
      .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');
    const wrapperAnimation = createAnimation()
      .addElement(root?.querySelector('.modal-wrapper')!)
      .keyframes([
        { offset: 0, opacity: '0', transform: 'scale(0)' },
        { offset: 1, opacity: '0.99', transform: 'scale(1)' },
      ]);
    return createAnimation()
      .addElement(baseEl)
      .easing('ease-out')
      .duration(500)
      .addAnimation([backdropAnimation, wrapperAnimation]);
  };

  const leaveAnimation = (baseEl: HTMLElement) => {
    return enterAnimation(baseEl).direction('reverse');
  };

  return (
    <IonModal
          className="promotion-modal-full-screen"
          ref={modal}
          // enterAnimation={enterAnimation}
          // leaveAnimation={leaveAnimation}
          isOpen={isOpenPromotion}
        >
          <IonContent>
            <IonToolbar>
              <IonButtons slot="end">
                <IonButton className="close-icon-container" onClick={handleCloseModal}>
                  <CloseIcon color="white" size={32}/>
                </IonButton>
              </IonButtons>
            </IonToolbar>
              <IonItem>
                <IonImg 
                  className="container-img"
                  src={`${process.env.REACT_APP_BFF_IMAGE}${promotion?.image?.url}`}
                  onClick={onImageClick}
                />
              </IonItem>
          </IonContent>
        </IonModal>

    // <IonModal 
    //   className="promotion-modal-full-screen" 
    //   backdropDismiss={true} 
    //   isOpen={isOpenPromotion}
    //   ref={modal}
    //   enterAnimation={enterAnimation}
    //   leaveAnimation={leaveAnimation}
    // >
    //   <div className="content">
    //     <div className="header">
    //       <div className="close-banner">
    //         <IonIcon icon={closeIcon} onClick={handleCloseModal} />
    //       </div>
    //     </div>
    //     <IonImg 
    //       className="container-img"
    //       src={`${process.env.REACT_APP_BFF_IMAGE}${promotion?.image?.url}`}
    //       onClick={onImageClick}
    //     />
    //     {/* <div 
    //       className="container-img" 
    //       style={{ backgroundImage: `url(${process.env.REACT_APP_BFF_IMAGE}${promotion?.image?.url})` }} 
    //       onClick={onImageClick}  
    //     /> */}
    //   </div> 
    // </IonModal>
  );
};

export default React.memo(PromotionModal);