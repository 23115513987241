import React from 'react';
import { createRoot } from 'react-dom/client';
import * as serviceWorker from './serviceWorker';
import '../src/assets/scss/styles.scss';
import '../src/assets/fonts/Nunito/Nunito-Regular.ttf';
// import '@ionic/core/css/core.css';
// import '@ionic/core/css/ionic.bundle.css';
// import 'guru-react-icons/dist/index.css';
import { Provider } from 'unstated-typescript';
import { IonApp } from '@ionic/react';
import { BrowserRouter, Switch } from 'react-router-dom';


import UserSessionProvider from './hoc/UserSessionProvider/UserSessionProvider';
// import { InitGoogleMapsApi } from './hoc/InitGoogleMapsApi';
import moment from 'moment';
import * as Sentry from '@sentry/browser';
import 'moment/locale/es';

// Configuramos Redux Toolkit
import MainApp from './MainApp';
import AppProvider from './context/AppProvider';
import { LoadScript } from '@react-google-maps/api';
import { Capacitor } from '@capacitor/core';
import { App as AppCapacitor } from '@capacitor/app';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import { DevicePermissions } from './utils/DevicePermissions';

declare global {
  interface Window {
    firebase: any;
  }
}

moment.locale('es');

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_NODE_ENV,
  release: process.env.REACT_APP_BUILD_NUMBER,
});

class App extends React.PureComponent<any, any> {
  componentDidMount() {
    if (Capacitor.isNativePlatform()) {
      AppCapacitor.addListener('appUrlOpen', this.handleDeepLink);
    }
  }

  handleDeepLink = (data: { url: string }) => {
    console.log({data})
    // Extraer la ruta del deeplink
    // const slug = data.url.split(process.env.REACT_APP_DEEPLINKS).pop();
    // if (slug) {
    //   // Navegar a la ruta correspondiente
    //   window.location.href = slug;
    // }
  }

  render() {
    return (
      <IonApp>
        <BrowserRouter>
          <Switch>
            <Provider>
              <AppProvider>
                <UserSessionProvider>
                <DevicePermissions/>
                <LoadScript
                  id='google-map-react'
                  googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_KEY || ''}
                  libraries={['places']}
                >
                    <MainApp />
                  </LoadScript>
                </UserSessionProvider>
              </AppProvider>
            </Provider>
          </Switch>
         </BrowserRouter>
      </IonApp>
    );
  }
}

// Refactorización para React 18
const container = document.getElementById('root');
if (container) {
  const root = createRoot(container); // Crear el root en React 18
  root.render(<App />); // Renderizar la aplicación en el root
}

serviceWorker.register();
