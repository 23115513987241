import React, { useState, useEffect } from 'react'
import { IonContent, IonPage } from '@ionic/react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { StaticContext } from 'react-router-dom'
import track from 'react-tracking'
import { eventForCart, eventWithName } from '../firebase/firebaseTags'
import { uniq } from 'lodash'

//COMPONENTS
import ToolBar from '../../components/tool-bar/ToolBar'
import { formatCurrency } from '../../utils/intl'
import CartControllerNew from '../cart-controller/CartControllerNew'
import GuruWhiteScreenProps from '../../components/guru-white-screen/GuruWhiteScreen'
import AlertModal from '../../components/modals/AlertModal'
import LoadingComponent from '../../components/loading-component/LoadingComponent'
import OrderConfirmedModal from '../../components/order-confirmed-modal/OderConfirmedModal'

//MODELS
import { ProviderModel } from '../../models/ProviderModel'
import { CartModel } from '../../models/CartModel'
import { ProductModel } from '../../models/ProductModel'
import { PromotionModel } from '../../models/PromotionModel'
import Category from '../../models/Category'
import Office from '../../models/Office'
import Settings from '../../models/Settings'
import User from '../../models/User'
import { CouponsActivatedModel } from '../../models/CouponsActivatedModel'
import { Cart, Products } from '../../models/CartModel2'
import { Coupon } from '../../models/Coupon'

//UTILS
import { ordersPaidEvents } from '../../utils/firebaseEvents'
import { areCartProductsLoading } from '../../utils/locationStateValidations'
import { registerBasicUserInfoEvent } from '../firebase/firebaseTags'

//ICONS
import Garbage from './../../assets/icons/act_delete.svg'

import { getCartId, getProductCart, deletCart, deleteProductFromCart, changePaymentMethod } from '../../clients/cartNew'

import './Cart.scss'
import { getLocalStorageObject } from '../../utils/localstorageData'
import { navigateToCart } from '../../utils/navigation'
import { getProviderShippingCost } from '../../clients/provider'
import { findShippingCost, totalProducts, verificarDescuento } from '../../utils/cart'
import { LoadingFullPage } from '../../components/loading-full-page/LoadingFullPage'

interface IPathParams {
  statePay?: string
}

interface PastRouteinfo {
  route?: string
  paymentMethod?: string
  cartId?: string
}
interface RouteState {
  eventSource?: any
  pastRouteInfo?: PastRouteinfo
  singleProviderId?: any
  nameProvider?: any
  minumun?: any
  banners?: any
}

type IProps = RouteComponentProps<IPathParams, StaticContext, RouteState> & {
  user: User
  settings: Settings
  offices: Office
  categories: Category
  promotion: PromotionModel
  productModel: ProductModel
  cartModel: CartModel
  providerModel: ProviderModel
  couponsActivatedModel: CouponsActivatedModel
  coupons: Coupon
}

const CartNew: React.FC<IProps> = (props) => {
  const { history, location } = props
  const [msg, setMsg] = useState<any>({ msg: null, active: false })
  const [cart, setCart] = useState<any>([])
  const [cartId, setCartId] = useState<string>('')
  const [productsGroupedByPaymentMethod, setProductsGroupedByPaymentMethod] = useState<any[]>([])
  const [counterCart, setCounterCart] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(true)
  const [couponAddCart, setCouponAddCart] = useState<any>([])
  const [totalDiscountCart, setTotalDiscountCart] = useState<number>(0)
  const [, setUser] = useState<any>({})
  const [couponsActivated, setCouponsActivated] = useState<any>([])
  const [arrPaymentMethodProvider, setArrPaymentMethodProvider] = useState<any>([])
  const [statusOrder, setStatusOrder] = useState<any>({ open: false, paid: false, subHeader: '', header: '' })
  const [openProviderSelect, setOpenProviderSelect] = useState<string>('')
  const [orderConfirmedModal, setOrderConfirmedModal] = useState<boolean>(false)
  const [isBottomSheetShowem, setIsBottomSheetShowem] = useState<boolean>(false)
  const [showModalOrderRepeat, setShowModalOrderRepeat] = useState<boolean>(false)
  const [arrPaymentMethodsByVendor, setArrPaymentMethodsByVendor] = useState<any>([])
  const [preventGoBack, setPreventGoBack] = useState(false)
  const [totalShippingCost, setTotalShippingCost] = useState(0)
  const [loadingChangePaymentMethod, setLoadingChangePaymentMethod] = useState(false)

  useEffect(() => {
    registerBasicUserInfoEvent({
      eventName: 'cart_page_view',
      placementName: 'cart'
    })
    const interval = setInterval(() => {
      if (!areCartProductsLoading()) {
        clearInterval(interval)
        getId()
      }
    }, 1000)
  }, []) 

  useEffect(() => {
    const interval = setInterval(() => {
      if (!areCartProductsLoading()) {
        clearInterval(interval)
        productCart()
      }
    }, 1000)
  }, [cartId]) 

  useEffect(() => {
    const interval = setInterval(() => {
      if (!areCartProductsLoading()) {
        clearInterval(interval)
        cartCounterByVendor()
      }
    }, 1000)
  }, [productsGroupedByPaymentMethod]) 

  const getId = async () => {
    localStorage.setItem('totalPre', JSON.stringify(0))
    localStorage.setItem('totalPost', JSON.stringify(0))

    const repeatOrder = localStorage.getItem('repeatOrder')
    const orderRepeat = repeatOrder && JSON.parse(repeatOrder)

    orderRepeat && setShowModalOrderRepeat(true)

    const idCart = await getCartId()
    const user: any = (await props.user.getUser()) || {}
    setUser(user)

    if (Object.keys(idCart).length > 0) {
      setCartId(idCart.data._id)
      await couponReferalAndChooseVendor()
      productCart()
      cartCounterByVendor()
    }

    if (props.match.params) {
      const statePay = props.match.params.statePay || undefined
      detailOfPay(statePay)
    }
  }

  const productCart = async () => {
    let productsFilterWhitelist = []
    let notHavePaymentMethods: any = []
    let paymentMethodsByUserByVendor: any = []

    if (cartId) {
      let productCart = await getProductCart(cartId)
      const cart = productCart.cart.products.filter((product: any) => !product.paid)
      productCart = {
        ...productCart.cart,
        products: cart,
      }

      let shippingCostVendors: any = null

      if (productCart.products.length > 0) {
        const cartIdProviders = uniq(productCart?.products.map((item: any) => item.providerId)).join()
        const whitelistVendors = await props.cartModel.whitelistByPaymentMethod(cartIdProviders)
        let providersLocal = localStorage.getItem('@providers') || ''
        let vendorsLocal = providersLocal ? JSON.parse(providersLocal) : {}

        shippingCostVendors = await getProviderShippingCost(cartIdProviders)

        for (const vendor of whitelistVendors) {
          const paymentMethodsVendors = vendorsLocal[vendor.vendorId]?.paymentMethods.reduce(
            (acc: any, current: any) => {
              acc.push(current.type)
              return acc
            },
            [],
          )

          const hasValidMethod = vendor?.whitelist.some((whitelistedMethod: any) =>
            paymentMethodsVendors?.includes(whitelistedMethod),
          )

          if (hasValidMethod) {
            let paymentMethodsByVendor = {
              idVendor: vendor.vendorId,
              whitelist: whitelistProveedorByUserByPaymentMethods(vendor?.whitelist, paymentMethodsVendors),
            }
            paymentMethodsByUserByVendor.push(paymentMethodsByVendor)
          } else {
            notHavePaymentMethods.push(vendor.vendorId)
          }
        }

        productsFilterWhitelist = notHavePaymentMethods.map((vendorId: any) =>
          productCart.products.filter((idProvider: any) => idProvider.providerId !== vendorId),
        )
      }

      let arrProductsFilterWhitelist: any = []
      productsFilterWhitelist.forEach((item: any) => {
        arrProductsFilterWhitelist = [].concat(item, arrProductsFilterWhitelist)
      })

      if (arrProductsFilterWhitelist && notHavePaymentMethods.length) {
        productCart = {
          ...productCart,
          products: arrProductsFilterWhitelist,
        }
      }
      setArrPaymentMethodsByVendor(paymentMethodsByUserByVendor)

      for (const vendor of paymentMethodsByUserByVendor) {
        if (vendor.whitelist.length === 1) {
          const updatedProduct = productCart.products.map((product: any) => {
            return product.providerId === vendor.idVendor ? { ...product, paymentForm: vendor.whitelist[0] } : product
          })
          productCart = {
            ...productCart,
            products: updatedProduct,
          }
        }
      }

      if (Object.keys(productCart).length > 0) {
        productCart && localStorage.setItem('@cart', JSON.stringify(productCart))

        setCart(productCart)

        setCouponAddCart(productCart.discounts)

        const paymentMethodsVendors = await productsByPaymentMethodsVendors(productCart)
        const productsDataToUpdate = productsToUpdate(productCart)

        const cartProducts: any = await updateCartProducts(productsDataToUpdate)
        paymentMethodsVendors && setLoading(false)
        const cart = cartProducts && productsInCart(paymentMethodsVendors, cartProducts)

        let totalShippingCost = 0

        const cartWithShippingCostVendors = [...cart].map((item) => {
          const shippingCosts: any = {}
          for (const vendor in item.vendors) {
            if (shippingCostVendors) {
              const findShippingCostProvider = shippingCostVendors[vendor]
              if (findShippingCostProvider && findShippingCostProvider.shippingCost) {
                const costs = findShippingCostProvider.shippingCost.costs
                shippingCosts[vendor] = costs
              }
            }
          }
          totalShippingCost += getShippingCostPaymenthMethodAndVendors(
            item.vendors || [],
            shippingCosts,
            productCart.discounts,
          )
          return { ...item, shippingCosts }
        })
        setProductsGroupedByPaymentMethod(cartWithShippingCostVendors)
        setTotalShippingCost(totalShippingCost)
      }
    }
  }

  const getShippingCostPaymenthMethodAndVendors = (vendors: any [], shippingCosts: any, coupons: any []) => {
    let getTotalShippingCost = 0
    for (const vendorId in vendors) {
      let totalPrice = totalProducts(vendors[vendorId])

      if (couponAddCart.length) {
        const discount = verificarDescuento(vendorId, coupons)
        totalPrice -= discount
      }
      const shippingCostVendor = findShippingCost(totalPrice, vendorId, shippingCosts)

      getTotalShippingCost += shippingCostVendor ? shippingCostVendor.cost : 0
    }
    return getTotalShippingCost
  }

  const whitelistProveedorByUserByPaymentMethods = (whitelist: any[], paymentMethodsVendors: any[]) => {
    let arrayExistPaymentMethods: any[] = []

    paymentMethodsVendors.forEach((methodVendor: any) => {
      let existPaymentMethods = whitelist.some((methodWhitelist: any) => methodVendor === methodWhitelist)
      if (existPaymentMethods) arrayExistPaymentMethods.push(methodVendor)
    })

    return arrayExistPaymentMethods
  }

  const findProductsByVendorsPaymentMethods = async (cart: Cart) => {
    const products = cart.products
    const paymentMethods = new Set(products.map((product: any) => product.paymentForm))

    const productsGroupedByPaymentMethod: any = Array.from(paymentMethods).map((paymentMethod: any) => {
      return {
        paymentMethod,
        vendors: {},
        products: {},
      }
    })

    let arrPaymentMethodProvider: any = []

    for (let i = 0; i < products.length; i++) {
      const productPaymentForm = products[i].paymentForm
      const methodIndex = productsGroupedByPaymentMethod.findIndex(
        (method: any) => method.paymentMethod == productPaymentForm,
      )

      if (methodIndex > -1) {
        const vendorId = products[i].providerId
        const vendorIndexExist = productsGroupedByPaymentMethod[methodIndex].vendors[vendorId]

        if (vendorIndexExist) {
          productsGroupedByPaymentMethod[methodIndex].vendors[vendorId].push(products[i])
        } else {
          productsGroupedByPaymentMethod[methodIndex].vendors[vendorId] = [products[i]]
        }

        arrPaymentMethodProvider = [
          ...arrPaymentMethodProvider,
          {
            type: productPaymentForm,
            providerId: vendorId,
          },
        ]
      }
    }

    setArrPaymentMethodProvider(arrPaymentMethodProvider)
    return productsGroupedByPaymentMethod
  }

  const productsToUpdate = (cart: any) => {
    return cart.products.map((product: any) => {
      return {
        providerId: product.providerId,
        sku: product.sku,
      }
    })
  }

  const updateCartProducts = async (productsData: any) => {
    const dataUpdated = await Promise.all(
      productsData.map((productData: any) =>
        props.productModel.getByProviderAndSku(productData.providerId, productData.sku),
      ),
    )
    return dataUpdated
  }

  const productsInCart = (cartProducts: any, products: any) => {
    let productsInCart = cartProducts

    for (let itemCart = 0; itemCart < cartProducts.length; itemCart++) {
      const vendors = cartProducts[itemCart].vendors
      const providers = Object.keys(vendors)
      const productPaymentForm = cartProducts[itemCart]
      for (let vendorKey = 0; vendorKey < providers.length; vendorKey++) {
        const vendor = vendors[providers[vendorKey]]

        for (let item = 0; item < vendor.length; item++) {
          const match = products.find((prod: any) => prod && prod.sku == vendor[item].sku)
          const productsArray = cartProducts[itemCart].products[providers[vendorKey]]
          const methodIndex = cartProducts.findIndex(
            (method: any) => method.paymentMethod == productPaymentForm.paymentMethod,
          )

          if (methodIndex > -1) {
            if (productsArray && productsArray.length) {
              productsInCart[methodIndex].products[providers[vendorKey]].push(match)
            } else {
              productsInCart[methodIndex].products[providers[vendorKey]] = [match]
            }
          }
        }
      }
    }

    return productsInCart
  }

  const toolBar = () => {
    return (
      <ToolBar
        title="Carrito"
        secondaryHeader={true}
        secondaryButtons={[
          {
            type: 'close',
            onClick: onGoBack,
          },
        ]}
        primaryButtons={[
          {
            icon: Garbage,
            key: 'clearCart',
            onClick: deleteCart,
          },
        ]}
        textCenter={true}
        boxShadow={true}
        bottomSheetShowem={isBottomSheetShowem}
      />
    )
  }

  const deleteCart = async () => {
    if (!loading) {
      const msg = {
        active: true,
        msg: '¿Estás seguro que quieres vaciar tu carrito?',
        header: 'Vaciar carro',
      }
      setMsg(msg)
    }
  }

  const modalDeleteCart = () => {
    return (
      <AlertModal
        label={msg.header}
        text={msg.msg}
        buttonText={'Sí'}
        isOpen={msg.active}
        buttonAction={() => cartDelete()}
        onDismiss={() => onDismissDeleteCart()}
      />
    )
  }

  const onDismissDeleteCart = () => {
    const msg = {
      active: false,
      msg: '',
      header: '',
    }
    setMsg(msg)
  }

  const emptyCar = () => {
    return (
      <div className="without-products-card">
        <GuruWhiteScreenProps text="Todavía no has agregado nada al carrito" />
      </div>
    )
  }

  const cartDelete = async () => {
    if (cartId) {
      const deleteCart = await deletCart(cartId)
      if (Object.keys(deleteCart).length > 0 && deleteCart.message === 'Cart was closed') {
        setProductsGroupedByPaymentMethod([])
        setCart([])
        localStorage.removeItem('@cart')
        const msg = {
          active: false,
          msg: '',
          header: '',
        }
        setMsg(msg)
      }
      localStorage.removeItem('confirmShoppingCartClickProviderTimeStart')
      registerBasicUserInfoEvent({
        eventName: 'cart_page_toolbar_clean_cart',
        placementName: 'cart'
      })
    }
  }

  const cartTotal = () => {
    const total =
      cart?.products &&
      cart.products
        .map((item: any) => {
          return item.infoPrice
        })
        .reduce((acc: any, current: any) => acc + current.totalPrice, 0)

    return total + totalShippingCost
  }

  const cartCounterByVendor = () => {
    let counter = 0
    for (let i = 0; i < productsGroupedByPaymentMethod.length; i++) {
      const productPaymentForm = productsGroupedByPaymentMethod[i].vendors
      counter = Object.keys(productPaymentForm).length + counter
    }
    setCounterCart(counter)
  }

  const removeCouponIfIsLastProduct = (vendorId: string, cartProduct: any, matchCoupon: any) => {
    const matchProducts = cart.products
      .filter((product: any) => product.providerId === vendorId)
      .filter((product: any) => product._id !== cartProduct._id)
    if (!matchProducts.length && matchCoupon)
      removeLocalCouponToCart({ checked: false, value: matchCoupon.couponId }, vendorId)
  }

  const removeProductFromCart = async (cartProduct: Products, product: any) => {
    const answer = await deleteProductFromCart(cartId, cartProduct._id)
    const eventSource = location?.state?.eventSource || ''
    const vendorId = cartProduct.providerId
    const matchCoupon = couponsActivated.find((coupon: any) => coupon.vendors.includes(vendorId))
    if (answer === 'Product was removed from the cart') {
      removeCouponIfIsLastProduct(vendorId, cartProduct, matchCoupon)
      productCart()
      // eventForCart('delete_product_cart', product.name, product.id, product.brand, product.category, eventSource)
    }
  }

  const couponReferalAndChooseVendor = async () => {
    try {
      const coupons = await props.coupons.getALLCoupons()
      setCouponsActivated(coupons)
    } catch (error) {
      console.error('Error----->', error)
    }
  }

  const amount = () => {
    return productsGroupedByPaymentMethod.length > 0 ? (
      <div className="container-total-cart">
        <div className="paragraph-2">Cantidad de carros: {counterCart}</div>
        <div className="paragraph-2">Total: {formatCurrency(cartTotal() - totalDiscountCart)}</div>
      </div>
    ) : (
      <div className="container-total-cart">
        <div className="paragraph-2">Cantidad de carros: {counterCart}</div>
        <div className="paragraph-2">Total: {formatCurrency(0)}</div>
      </div>
    )
  }

  const onProductMethodFormChangeHandler = async (event: any, providerId: any) => {
    setLoadingChangePaymentMethod(true)
    const methodSelect = event ? event.detail.value : ''
    await changePaymentMethod(cartId, providerId, methodSelect)
    await productCart()
    eventWithName('change_payment')
    setLoadingChangePaymentMethod(false)
  }

  const detailOfPay = async (statePay?: any) => {
    const validations = [
      {
        isValid: (value: string) => value === '0',
        action: () => {
          setStatusOrder({
            open: true,
            paid: false,
            subHeader: 'Tarjeta esta registrada en el sistema',
            header: 'Duplicado',
            nameBtn: 'Ok',
          })
        },
      },
      {
        isValid: (value: string) => value === '1',
        action: () => {
          ordersPaidEvents()
          setOrderConfirmedModal(true)
        },
      },
      {
        isValid: (value: string) => value === '2',
        action: () => {
          setStatusOrder({
            open: true,
            paid: false,
            subHeader: 'Tu método de pago ha sido agregado con éxito. Ya puedes realizar tus compras',
            header: '¡Felicidades!',
            nameBtn: 'Ok',
          })
        },
      },
      { isValid: (value: string) => value === '-1', action: onPaymentError },
    ]

    statePay &&
      validations.forEach((validation) => {
        if (validation.isValid(statePay)) {
          validation.action()
        }
      })
  }

  const onPaymentError = () => {
    setStatusOrder({
      open: true,
      paid: false,
      subHeader: 'El pago de tu pedido no se pudo realizar. Si persiste el problema, comunícate con nosotros',
      header: 'Pago fallido',
      nameBtn: 'Vamos de nuevo',
    })

    setPreventGoBack(true)
  }

  const showAlertDetailOfPay = () => {
    return (
      <AlertModal
        label={statusOrder.header}
        text={statusOrder.subHeader}
        buttonText={statusOrder.nameBtn}
        isOpen={statusOrder.open}
        buttonAction={() => (statusOrder.paid ? goToOrders() : dismissAlertOrder())}
        onDismiss={() => (statusOrder.paid ? goToOrders() : dismissAlertOrder())}
      />
    )
  }

  const dismissAlertOrder = () => {
    setStatusOrder({ statusOrder: { paid: false, open: false } })
    navigateToCart(history, props.location.state || history.location.state)
  }

  const prividerSelectCartChange = (providerId: string) => setOpenProviderSelect(providerId)

  const addCouponToLocalCart = (idCoupon: any, idProvider: any) => {
    const coupon = {
      amount: '0',
      applyDiscount: true,
      code: '',
      couponId: `${idCoupon.value}`,
      couponType: 'referal',
      discountType: 'amount',
      minimumAmount: 0,
      provider: null,
      providerId: `${idProvider}`,
      realDiscount: 0,
    }
    let cartCopy = { ...cart }

    cartCopy.discounts.push(coupon)
    setCart(cartCopy)
  }

  const removeCouponToLocalCart = (idCoupon: any) => {
    let cartCopy = { ...cart }
    let discounts = cartCopy.discounts.filter((coupon: any) => coupon.couponId !== idCoupon.value)
    cartCopy.discounts = discounts

    setCart(cartCopy)
  }

  const addCouponToCart = async (idCoupon: any, idProvider: any) => {
    try {
      addCouponToLocalCart(idCoupon, idProvider)
      await props.coupons.cartAddCoupon(cartId, idProvider, idCoupon.value)
      await couponReferalAndChooseVendor()
      productCart()
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const removeCouponToCart = async (idCoupon: any, idProvider: any) => {
    try {
      removeCouponToLocalCart(idCoupon)
      await props.coupons.deactivateCoupon(cartId, idCoupon.value)
      await couponReferalAndChooseVendor()
      productCart()
      await props.cartModel.removeDiscount(idProvider)
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const removeLocalCouponToCart = async (idCoupon: any, idProvider: any) => {
    try {
      removeCouponToLocalCart(idCoupon)
      await couponReferalAndChooseVendor()
      productCart()
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const isBottomSheetShow = (isBottomSheetShowem: any) => setIsBottomSheetShowem(isBottomSheetShowem)

  const updateCart = async (checkLoadingProducts: boolean) => {
    setLoading(true)

    if (checkLoadingProducts) {
      const interval = setInterval(async () => {
        if (!areCartProductsLoading()) {
          clearInterval(interval)
          productCart()
          getId()
          cartCounterByVendor()
        }
      }, 1000)
    } else {
      await productCart()
      cartCounterByVendor()
    }
  }

  const renderProductsGroupedByPaymentMethod = () => {
    const pastRouteInfo: PastRouteinfo = { ...props.history.location?.state?.pastRouteInfo, cartId: cartId }

    return productsGroupedByPaymentMethod.map((payment: any, index: number) => {
      return (
        <div key={index}>
          <CartControllerNew
            paymentMethodCart={payment}
            productsGroupedByPaymentMethod={productsGroupedByPaymentMethod}
            removeProductFromCart={removeProductFromCart}
            pastRouteInfo={pastRouteInfo}
            cartId={cartId}
            couponAddToCart={couponAddCart}
            totalDiscount={totalDiscount}
            couponsListActivated={couponsActivated}
            arrPaymentMethodProvider={arrPaymentMethodProvider}
            onProductMethodFormChangeHandler={onProductMethodFormChangeHandler}
            index={index}
            productsIncart={cart}
            cartChangePrividerSelect={prividerSelectCartChange}
            providerSelectOpen={openProviderSelect}
            cartAddCoupon={addCouponToCart}
            couponToCartRemove={removeCouponToCart}
            bottomSheetShow={isBottomSheetShow}
            arrPaymentMethodsByVendor={arrPaymentMethodsByVendor}
            {...props}
            updateCart={updateCart}
          />
        </div>
      )
    })
  }

  const closeModalOrderRepeat = () => {
    setShowModalOrderRepeat(false)
    localStorage.removeItem('repeatOrder')
  }

  const productsByPaymentMethodsVendors = (cart: Cart) =>
    Object.keys(cart).length && findProductsByVendorsPaymentMethods(cart)

  const totalDiscount = (total: number) => setTotalDiscountCart(total)

  const onGoBack = () => {

    registerBasicUserInfoEvent({
      eventName: 'cart_page_toolbar_go_back',
      placementName: 'cart_page'
    })

    const onEditProduct = localStorage.getItem('onEditProduct')
    const isEditProduct = onEditProduct && JSON.parse(onEditProduct)

    const singleProviderStorage = localStorage.getItem('@providers')
    const singleProviderObject = singleProviderStorage ? JSON.parse(singleProviderStorage) : null

    if (isEditProduct && Object.keys(isEditProduct).length) {
      const providerEdit: any = Object.values(singleProviderObject).find((item: any) => item.id === isEditProduct.id)
      localStorage.removeItem('onEditProduct')
      return goToHomeProvider(providerEdit)
    }

    const cartNavigationData = getLocalStorageObject('cartNavigation')
    if (!preventGoBack || !cartNavigationData) return history.goBack()

    const { pathname, state } = cartNavigationData
    history.push(pathname, state)
    localStorage.removeItem('cartNavigationData')
  }

  const goToHomeProvider = (providerEdit: any) => {
    props?.history?.push(`/home-providers/${providerEdit.id}`, {
      singleProviderId: providerEdit.id,
      nameProvider: providerEdit.alias,
      minumun: providerEdit.minimun,
      banners: providerEdit.banners,
    })
  }

  const goToOrders = () => history.push('/orders')

  return (
    <IonPage className="cart-page-class ds-content-page" >
      {!orderConfirmedModal && toolBar()}
      {!orderConfirmedModal && (
        <IonContent >
          {amount()}
          <div className="separator-line"></div>
          {modalDeleteCart()}

          {loading && <LoadingComponent />}

          <div className={loading ? 'hide-container' : ''}>
            {productsGroupedByPaymentMethod.length > 0 && renderProductsGroupedByPaymentMethod()}
            {productsGroupedByPaymentMethod.length === 0 && !loading && emptyCar()}
          </div>

          {statusOrder.open && showAlertDetailOfPay()}

          {loadingChangePaymentMethod && <LoadingFullPage />}
        </IonContent>
      )}
      {orderConfirmedModal && <OrderConfirmedModal onClick={() => goToOrders()} />}
      {showModalOrderRepeat && !loading && (
        <AlertModal
          label={'Productos sin stock'}
          text={'Algunos de los productos que quieres volver a comprar, se encuentran sin stock.'}
          buttonText={'Continuar'}
          onDismiss={closeModalOrderRepeat}
          isOpen={showModalOrderRepeat}
          buttonAction={() => closeModalOrderRepeat()}
        />
      )}
      <style>
        {`
          .footer-menu-home {
            display: none !important;
          }
        `}
      </style>
    </IonPage>
  )
}

export default track({ page: 'Cart' })(withRouter(CartNew))
