import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { StaticContext } from 'react-router-dom'

import ProductCard from '../../components/product-card/ProductCard'
import actOpen from './../../assets/icons/act_more.svg'
import actClose from './../../assets/icons/act_less.svg'

import User from '../../models/User'
import Category from '../../models/Category'
import Settings from '../../models/Settings'
import { ProductModel } from '../../models/ProductModel'
import { CartModel } from '../../models/CartModel'
import { ifExistAndMayorToZero } from '../../utils/arrays'
import actRight from './../../assets/icons/act_right.svg'
// TODO: resolver import GuruIcon from 'guru-react-icons'

import './CategorySecondary.scss'
import { navigateToCart } from '../../utils/navigation'
import { VisibilityTracker } from '../../hoc'
import { registerProductSliderItemEventClick, registerProductSliderItemEventView } from '../firebase/firebaseTags'

interface IPathParams { }

type Props = RouteComponentProps<IPathParams, StaticContext> & {
  subcategories: any
  singleProvider: any
  user: User
  settings: Settings
  categories: Category
  productModel: ProductModel
  tracking?: any
  cartModel: CartModel
  loadMoreProductsForCategory: (subcategory: any) => void
  seeLessProductsForCategory: any
  productsInfo: any
  nameProvider?: string
  arrowCategory?: boolean
  placement?: string
}


const CategorySecondary: React.FC<Props> = props => {
  const onGoToCart = () => {
    navigateToCart(props.history, props.location.state || props.history.location.state);
  }

  const onMoreInfoClick = (category: any) => {
    const { singleProvider, nameProvider, arrowCategory } = props

    const toGoRoute = `/provider/${singleProvider}/category/${category._id}`
    const params = {
      title: category.name,
      singleProvider,
      categoryId: category._id,
      tree: category.tree || [],
      category,
      nameProvider:nameProvider,
      arrow: arrowCategory
    }

    props?.history?.push(toGoRoute, params)
  }

  const placement = props.placement ? props.placement + '_category_secondary' : 'category_secondary'
  const handleWhenProductIsVisible = (product: any) => {
    registerProductSliderItemEventView({
      productId: product.id,
      productName: product.name,
      productBrand: product.brand,
      productCategory: product.category,
      productSku: product.sku,
      providerId: product.providerId,
      providerName: product.providerName,
      placementName: placement,
    })
    
  }

  const handleProductClick = async (product: any) => {
    registerProductSliderItemEventClick({
      productId: product.id,
      productName: product.name,
      productBrand: product.brand,
      productCategory: product.category,
      productSku: product.sku,
      providerId: product.providerId,
      providerName: product.providerName,
      placementName: placement,
    })
  }

  const mapSubcategoriesProducts = (subcategory: any) => (
    <div key={subcategory._id} id={subcategory._id}>
      {
        ifExistAndMayorToZero(subcategory.products) &&
        subcategory.products.map((product: any) => {

          return (
            <div className="container-card" key={product.id} onClick={() => handleProductClick(product)}>
              <VisibilityTracker onVisible={() => handleWhenProductIsVisible(product)} key={product.id}>
                <ProductCard
                  key={product.id}
                  product={product}
                  settings={props.settings}
                  cartModel={props.cartModel}
                  onGoToCart={onGoToCart}
                  history={props.history}
                ></ProductCard>
              </VisibilityTracker>
            </div>
          )
        })
      }
    </div>
  )

  const showMoreBtn = (subcategory: any): boolean => (
    props.productsInfo[subcategory._id] &&
    props.productsInfo[subcategory._id].totalPages > 1 &&
    props.productsInfo[subcategory._id].page !== props.productsInfo[subcategory._id].totalPages
  )

  const showLessBtn = (subcategory: any): boolean => (
    props.productsInfo[subcategory._id] &&
    props.productsInfo[subcategory._id].totalPages > 1 &&
    props.productsInfo[subcategory._id].page === props.productsInfo[subcategory._id].totalPages
  )

  return (
    <div className="product-slider-secundary">
      {
        ifExistAndMayorToZero(props.subcategories) &&
        props.subcategories.map((subcategory: any) => {
          return (
            <div key={subcategory._id} className="container-subCategory">
              <div className="header" id={`header-${subcategory._id}`}>
                <span>{subcategory.name}</span>
                {
                  subcategory.hasSubcategories && (
                    <div className="seeMore">
                      <div
                        className="button-more"
                        onClick={() => onMoreInfoClick(subcategory)}
                      >
                        <div className="see-all-products">Todos</div> 
                        <img src={actRight}/>
                      </div>
                    </div>
                  )
                }
              </div>
              <div className="body">
                {mapSubcategoriesProducts(subcategory)}
                { showMoreBtn(subcategory) &&
                  <div className="provider-summary-order">
                    <div onClick={() => props.loadMoreProductsForCategory(subcategory)} className={`provider-header-order`}>
                      <div className="container-provider-header">
                      <div className="provider-title">
                        Ver más
                      </div>
                        <div className="icon-provider">
                          <img src={actOpen}/>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                { showLessBtn(subcategory) &&
                  <div className="provider-summary-order">
                    <div onClick={() => props.seeLessProductsForCategory(subcategory)} className={`provider-header-order`}>
                      <div className="container-provider-header">
                      <div className="provider-title">
                        Ver menos
                      </div>
                        <div className="icon-provider">
                          <img src={actClose}/>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

export default withRouter(CategorySecondary)